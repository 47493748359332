var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainCon"},[(!_vm.hideWelcomeMsg)?_c('div',{staticClass:"text-h1 animate__animated animate__fadeIn",attrs:{"id":"welcomeMsg"}},[_vm._v(" ようこそ ")]):_c('v-card',{staticClass:"animate__animated animate__fadeIn"},[_c('div',{staticClass:"white--text formLabel primary"},[_vm._v(" 管理者初期登録 ")]),_c('v-form',{staticClass:"pa-6",model:{value:(_vm.formData.valid),callback:function ($$v) {_vm.$set(_vm.formData, "valid", $$v)},expression:"formData.valid"}},[_c('v-text-field',{attrs:{"label":"名前","type":"text","rules":[
                    function (v) { return (v || '').length >= 2 && (v || '').length <= 20 || '2文字以上かつ20文字以下を入力してください'; } 
                ],"outlined":"","dense":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-text-field',{attrs:{"label":"Eメール","type":"email","rules":[
                    function (v) { return _vm.validateEmail(v) || '有効なメール形式ではありません'; } 
                ],"outlined":"","dense":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('v-text-field',{attrs:{"label":"パスワード","type":"password","rules":[
                    function (v) { return (v || '').length >= 8 || '最小８文字入れてください'; } 
                ],"outlined":"","dense":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('v-text-field',{attrs:{"label":"パスワード再入力","type":"password","rules":[
                    function (v) { return v === _vm.formData.password  || 'パスワードが一致していない'; } 
                ],"outlined":"","dense":""},model:{value:(_vm.formData.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirm", $$v)},expression:"formData.passwordConfirm"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"font-weight-bold success",attrs:{"disabled":!_vm.formData.valid},on:{"click":function($event){return _vm.register()}}},[_vm._v("登録")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }