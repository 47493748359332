<template>
    <div class="mainCon">
        <div v-if="!hideWelcomeMsg" id="welcomeMsg" class="text-h1 animate__animated animate__fadeIn">
            ようこそ
        </div>
        <v-card v-else class="animate__animated animate__fadeIn">
            <div class="white--text formLabel primary">
                管理者初期登録
            </div>
            <v-form 
                v-model="formData.valid"
                class="pa-6"
            >
                <v-text-field 
                    label="名前"
                    type="text"
                    :rules="[
                        v => (v || '').length >= 2 && (v || '').length <= 20 || '2文字以上かつ20文字以下を入力してください' 
                    ]"
                    v-model="formData.name"
                    outlined dense
                />
                <v-text-field 
                    label="Eメール"
                    type="email"
                    :rules="[
                        v => validateEmail(v) || '有効なメール形式ではありません' 
                    ]"
                    v-model="formData.email"
                    outlined dense
                />
                <v-text-field 
                    label="パスワード"
                    type="password"
                    :rules="[
                        v => (v || '').length >= 8 || '最小８文字入れてください' 
                    ]"
                    v-model="formData.password"
                    outlined dense
                />
                <v-text-field 
                    label="パスワード再入力"
                    type="password"
                    :rules="[
                        v => v === formData.password  || 'パスワードが一致していない' 
                    ]"
                    v-model="formData.passwordConfirm"
                    outlined dense
                />
                <div class="d-flex justify-center">
                    <v-btn 
                        :disabled="!formData.valid"
                        class="font-weight-bold success"
                        @click="register()"
                    >登録</v-btn>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data:()=>({
        hideWelcomeMsg:false,
        formData:{
            valid:false,
            name:"",
            email:"",
            password:"",
            passwordConfirm:"",
            initAdmin:true
        }
    }),
    mounted(){
        this.runAnimations()
    },
    methods:{
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        runAnimations(){
            let target = document.getElementById('welcomeMsg')
            setTimeout(()=>{
                target.classList.toggle('animate__fadeOut')
            },3000)
            setTimeout(()=>{
                this.hideWelcomeMsg = true
            },4000)
        },
        async register(){
            try{
                this.$store.commit('setLoadingScreen',{display:true})
                await axios.post('/auth/register', this.formData)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"success",
                    icon:"mdi-alert-circle",
                    text: "登録しました！" 
                })
                this.$store.commit('setLoadingScreen',{display:false})
                this.$router.replace("/")
            }catch(err){
                if(this.$store.state.inDevelopment)
                    console.log(err)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"red",
                    icon:"mdi-alert-circle",
                    text: err.response? err.response.data  :"エラーが発生しました。" 
                })
                this.$store.commit('setLoadingScreen',{display:false})
            }
        }
    }
}
</script>

<style scoped>
.mainCon{
    display:grid;
    place-items: center;
    width:100vw;
    height:98vh;
}
.formLabel{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}
</style>